import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AbpHttpInterceptor } from 'abp-ng2-module';

import * as ApiServiceProxies from './service-proxies';
import { LoaderInterceptor } from '@shared/interceptors/loader.interceptor';

@NgModule({
    providers: [
        ApiServiceProxies.RoleServiceProxy,
        ApiServiceProxies.SessionServiceProxy,
        ApiServiceProxies.TenantServiceProxy,
        ApiServiceProxies.UserServiceProxy,
        ApiServiceProxies.TokenAuthServiceProxy,
        ApiServiceProxies.AccountServiceProxy,
        ApiServiceProxies.ConfigurationServiceProxy,
        ApiServiceProxies.DocumentServiceProxy,
        ApiServiceProxies.CustomerServiceProxy,
        ApiServiceProxies.DocumentDynamicPropertyServiceProxy,
        ApiServiceProxies.DocumentCompilationServiceProxy,
        { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: AbpHttpInterceptor, multi: true },
        ApiServiceProxies.PaperworkServiceProxy,
        ApiServiceProxies.PaperworkTemplateServiceProxy,
    ],
})
export class ServiceProxyModule {}
